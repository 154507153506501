import React, { FC, useContext } from "react";
import { FooterNavigationProps } from "../generated-types";
import { Box, Flex, SimpleGrid, Text } from "@chakra-ui/react";
import { Link } from "~/foundation/Components/Link";
import { ContentWrapper } from "~/foundation/Components/ContentWrapper";
import { Icon, IconType } from "~/foundation/Components/Icon";
import { withFooterTheme } from "~/foundation/Theme/enhancers/withFooterTheme";
import { ColorThemeContext } from "~/foundation/Theme/ColorThemeContext";

const FooterNavigation: FC<FooterNavigationProps> = ({ rendering }) => {
	const [theme] = useContext(ColorThemeContext);

	return (
		<ContentWrapper py="0" mb={["8", null, null, "16"]}>
			<nav>
				<Flex flexFlow={['column', null, 'row']} justifyContent="space-between">
					{rendering.customData?.menuItems?.map((footerNavGroup, index) =>
						<Box
							key={index}
							color={theme.colors.footer.color}
							flexBasis={['100%', null, '25%']}
							justifyItems="flex-start"
							pe={["0", null, "8"]}
							me={["0", null, "1"]}
							mb={["10", null, null, "0"]}>
							<Text variant="footerHeading"
								mb={["4", null, "7"]}>
								{footerNavGroup.title}
							</Text>
							<SimpleGrid display={["grid", null, "inline-grid"]} columns={[2, null, 1]} spacing="1.125rem">
								{footerNavGroup.menuItemChildren?.filter(x => x.url).map((footerNavItem, subIndex) => {
									const cookieConsentLink = footerNavItem.url === "#cookie-consent";

									return (
										<Link
											key={subIndex}
											to={footerNavItem.url!}
											{...(footerNavItem.target && { target: footerNavItem.target })}
											variant="unstyled"
											size="md"
											color={theme.colors.footer.color}
											{...(cookieConsentLink && {
												onClick: (e) => {
													e.preventDefault();
													window.OneTrust?.ToggleInfoDisplay();
												}
											})}
											dataLayer={() => ({
												event: "GAevent",
												event_type: "click",
												event_name: "footer",
												group: footerNavGroup.title,
												text: footerNavItem.title,
												url: footerNavItem.url
											})}>
											{footerNavItem.title}
										</Link>
									);
								})}
							</SimpleGrid>
						</Box>
					)}
					<Box flexBasis="auto" flexGrow={["0", null, "1"]} />
					<Box flexBasis={["100%", null, "10rem"]} mt={["2", null, "12"]}>
						<Flex justifyContent={["space-between", null, "flex-end"]} alignContent="flex-start" flexFlow={["row no-wrap", null, "row wrap"]} mx="0" maxW="none">
							{rendering.customData?.socialMediaItems?.filter(x => x.url).map((socialMediaItem, index) => {
								let iconType: IconType;
								switch (socialMediaItem.title) {
									case "Facebook":
										iconType = "Facebook";
										break;
									case "Instagram":
										iconType = "Instagram";
										break;
									case "LinkedIn":
										iconType = "LinkedIn";
										break;
									case "Twitter":
										iconType = "TwitterX";
										break;
									case "X":
										iconType = "TwitterX";
										break;
									case "Youtube":
										iconType = "Youtube";
										break;
									default:
										return "";
								}

								return (
									<Box mt={["2", null, "4"]} mb="2" ms={["0", null, "8"]} me={["0", null, "0"]} key={index} color={["primary.neutralGrey", null, "footer.fg"]}>
										<Link
											to={socialMediaItem.url!}
											ariaLabel={socialMediaItem.title}
											isExternal
											target="_blank"
											rel="noopener"
											color={theme.colors.footer.color}
											_hover={{
												color: "primary.aramcoBlue"
											}}
											dataLayer={() => ({
												event: "GAevent",
												event_type: "click",
												event_name: "footer",
												group: "SoMe",
												text: socialMediaItem.title,
												url: socialMediaItem.url!
											})}>
											<Flex h="45px"
												w="45px"
												justifyContent="center"
												alignItems="center"
												borderRadius="50%"
												borderColor="currentColor"
												borderWidth=".125rem">
												<Icon
													variant={iconType}
													className="icon"
												/>
											</Flex>
										</Link>
									</Box>
								)
							})
							}
						</Flex>
					</Box>
				</Flex>
			</nav>
		</ContentWrapper>
	);
};

export default withFooterTheme({ fallbackToPageTheme: false })(FooterNavigation);